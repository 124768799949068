// const filterDate = document.querySelector('.flex-filters .filter-date');
const blocList = document.querySelector('.container.loop');
const loading = document.querySelector('.get-more-items');

let loadingMore = false;

const initFilterClick = () => {
	const catAll = document.querySelector('.filter-all a');
	const currentCat = document.querySelector('.current-cat:not(.filter-all) a')
	if (catAll !== null && currentCat !== null) {
		currentCat.addEventListener('click', (e) => {
			e.preventDefault();
			window.location = catAll.href;
		});
	}
};

const initFilterMobile = () => {
	const filtersBloc = document.querySelector('.flex-filters-wrapper');
	const filterMobile = document.querySelector('.filter-mobile');

	if (filterMobile !== null && filtersBloc !== null) {
		filterMobile.addEventListener('click', (e) => {
			e.preventDefault();
			filtersBloc.classList.toggle('filters--open');
		})
	}
};

const loadMoreItems = (blocList, action, cpt, paged, cat, hashtag) => {
	// const loadMoreItems = (blocList, paged, cat, order) => {
	if (blocList !== null) {

		let actionItems = `action=${action}&pagination=${paged}&cpt=${cpt}`;
		// let actionItems = `action=get_more_posts&order=${order}&paged=${paged}`;

		if (cat !== '') {
			actionItems = `${actionItems}&cat=${cat}`;
    }
    
    if (hashtag !== '') {
			actionItems = `${actionItems}&hashtag=${hashtag}`;
		}

		const queryListParams = {
			method: 'post',
			url: php_to_js.ajaxUrl,
			data: actionItems
		};

		loadingMore = true;
		let loadingAnimation = false;
		if (loading !== null) {
			loadingAnimation = lottie.loadAnimation({
        container: loading.querySelector('.loading-items'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: blocList.dataset.animationPath, // the path to the animation json
      });
			loading.classList.add('show');
		}

		axios(queryListParams)
			.then(function (response) {
				// handle success

				if (blocList !== null && response.data !== '') {
					// loading.classList.remove('show');
					document.querySelector('.container.loop > .row').innerHTML += response.data;
					blocList.dataset.paged = paged;
					loadingMore = false;
					loading.classList.remove('show');
					loadingAnimation.destroy();
				}
			})
			.catch(function (error) {
				// handle error
				console.error(error);
			});
	}
}

const askForMoreItems = (blocList) => {
	if (blocList !== null) {
		// const order = filterDate.dataset.order;
		const paged = parseInt(blocList.dataset.paged, 10);
		const max = parseInt(blocList.dataset.pagesMax, 10);
		const action = blocList.dataset.action;
		const category = blocList.dataset.category !== '' ? blocList.dataset.category : '';
		const hashtag = blocList.dataset.hashtag !== '' ? blocList.dataset.hashtag : '';
		const type = blocList.dataset.type;
		if ( paged < max ) {
			// loadMoreItems( blocList, (paged + 1), category, order );
			loadMoreItems( blocList, action, type, (paged + 1), category, hashtag );
		}
	}
}

const initFilterItems = () => {
  if (blocList !== null) {
    window.addEventListener('scroll', () => {
      const scrollTop = window.scrollY;
      const scrollCurrent = scrollTop + window.innerHeight;
      if(scrollCurrent + 200 >= document.body.clientHeight && !loadingMore) {
        askForMoreItems(blocList);
      }
    });
  }
}
	// filterDate.addEventListener('click', () => {
	// 	const order = filterDate.dataset.order;
	// 	const listCat = blocList.dataset.category;
	// 	const newOrder = order === 'desc' ? 'asc' : 'desc';
	// 	const list = document.querySelector('.flex-list .row');

	// 	let action = 'get_more_posts';

	// 	if (listCat !== '') {
	// 		action = 'get_more_posts_cat';
	// 	}

	// 	const queryParams = {
	// 		method: 'post',
	// 		url: ajax_url,
	// 		data: `action=${action}&order=${newOrder}&paged=1&cat=${listCat}`
	// 	};

	// 	if (loading !== null) {
	// 		loading.classList.add('show');
	// 	}

	// 	loadingMore = true;
	// 	blocList.dataset.paged = 1;

	// 	if (list !== null) {
	// 		list.innerHTML = '';
	// 	}

	// 	axios(queryParams)
	// 		.then(function (response) {

	// 			// handle success
	// 			if (order === 'desc') {
	// 				filterDate.dataset.order = 'asc';
	// 				filterDate.classList.remove('desc');
	// 				filterDate.classList.add('asc');
	// 			} else {
	// 				filterDate.dataset.order = 'desc';
	// 				filterDate.classList.remove('asc');
	// 				filterDate.classList.add('desc');
	// 			}

	// 			if (list !== null && response.data !== '') {
	// 				loading.classList.remove('show');
	// 				list.innerHTML = response.data;
	// 				loadingMore = false;
	// 			}
	// 		})
	// 		.catch(function (error) {
	// 			// handle error
	// 			console.error(error);
	// 		});

	// });
// }
