const addInstagramOverlay = () => {
  const instaTiles = document.querySelectorAll('.sbi_photo');
  if (instaTiles.length > 0) {
    document.body.classList.add('has-instagram-tiles')
    const instaImgs = document.querySelectorAll('.sbi_photo > img');
    instaImgs.forEach((el, index) => {
      let alt = el.alt;
      alt = alt.replace('\n', '<br>');
      const overlayDiv = document.createElement('div');
      overlayDiv.classList.add('insta-overlay');
      const overlayText = document.createElement('p');
      overlayText.innerHTML = alt;
      overlayDiv.appendChild(overlayText);
      instaTiles[index].appendChild(overlayDiv);
    });
  }
};
