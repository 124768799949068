// const initFloatingLabel = (elem, event) => {
//   elem.parentNode.parentNode.querySelector('label').classList.add('filled');
//   if (event.target.value === '') {
//     elem.parentNode.parentNode.querySelector('label').classList.remove('filled');
//   }
// }

const initErrorForm = () => {
  const validationErrors = document.querySelectorAll('.gfield_description.validation_message');
  const uploadMessages = document.querySelectorAll('.ginput_container_fileupload .validation_message ul');
  if (validationErrors !== null) {
    Array.from(validationErrors).forEach((validationError) => {
      const iconError = document.createElement('img');
      iconError.src = php_to_js.iconError.url;
      iconError.classList.add('gform_error');
      validationError.append(iconError);
    })
  }

  if (uploadMessages !== null) {
    Array.from(uploadMessages).forEach((uploadMessage) => {
      if (uploadMessage.children.length > 0) {
        const iconError = document.createElement('img');
        iconError.src = php_to_js.iconError.url;
        iconError.classList.add('gform_error');
        uploadMessage.append(iconError);
      }
    })
  }
}

function gformDeleteUploadedFileCustom(formId, fieldId, deleteButton) {
  const validationMessage = document.querySelector(".field_cv .validation_message ul");
  if (validationMessage !== null) {
    validationMessage.innerHTML = '';
    validationMessage.parentNode.style.position = 'absolute';
  }
  gformDeleteUploadedFile(formId, fieldId, deleteButton);
}

const changeMarkup = () => {
  if (window.gform !== undefined) {
    gform.addFilter('gform_file_upload_markup', function (html, file, up, strings, imagesUrl, response) {
      var formId = up.settings.multipart_params.form_id,
      fieldId = up.settings.multipart_params.field_id;
      html = '<div class="gf_preview_inner"><strong>' + file.name + "</strong> <img class='gform_delete' "
      + "src='" + php_to_js.imagesUrl + "form/delete.png' "
      + "onclick='gformDeleteUploadedFileCustom(" + formId + "," + fieldId + ", this);' "
      + "alt='" + strings.delete_file + "' title='" + strings.delete_file + "' /></div>";
    
      return html;
    });

    gform.addFilter( 'gform_file_upload_status_markup', function( statusMarkup, file, size, strings, removeFileJs, up ) {
        // Customize statusMarkup as desired.
        if (file.id !== '' && up.state) {
          setTimeout(function() {
            const elementAdded = document.querySelector('#'+file.id);
            if (elementAdded !== null) {
              setTimeout(function() {
                if (elementAdded.children.length > 0) {
                    elementAdded.classList.add('add--success');
                }
              }, 300)
            }
            const validationMessage = document.querySelector(".field_cv .validation_message ul");
              if(validationMessage !== null && validationMessage.children.length > 0) {
                validationMessage.parentNode.style.position = 'relative';
              }
              initErrorForm();
          }, 300)
        }
        return statusMarkup;
    } );
  }
}

const initPictoPj = () => {
  const btnPj = document.querySelector('body .gform_wrapper .ginput_container_fileupload');
  if (btnPj !== null) {
    const icon = document.createElement('img');
    icon.src = php_to_js.iconPj.url;
    icon.classList.add('gform_pj');
    btnPj.prepend(icon);
    // btnPj.dataset.iconPath = php_to_js.iconPj.url;
    // document.documentElement.style.setProperty('--icon-pj', php_to_js.iconPj.url);
  }
}

const init_gform_values = () => {
  const gforms_input = document.querySelectorAll('.gform_wrapper input[type="text"], .gform_wrapper input[type="email"]');
  if (gforms_input !== null) {
    Array.from(gforms_input).forEach((gform) => {
      if (gform.value !== '') {
        gform.parentNode.parentNode.querySelector('label').classList.add('filled');
      }
      gform.addEventListener('keyup', (event) => {
          event.target.setAttribute('value', event.target.value);
          event.target.parentNode.parentNode.querySelector('label').classList.add('filled');
          // if (event.target.value === '') {
          //   event.target.parentNode.parentNode.querySelector('label').classList.remove('filled');
          // }
      });

      gform.addEventListener('focus', (e) => {
        e.target.parentNode.parentNode.querySelector('label').classList.add('filled');
      });

      gform.addEventListener('blur', (e) => {
        if (e.target.value === '') {
          e.target.parentNode.parentNode.querySelector('label').classList.remove('filled');
        }
      });
    });
  }

  


  // const gforms_select = document.querySelectorAll('.gform_wrapper select');
  // if (gforms_select !== null) {
  //   Array.from(gforms_select).forEach(function(select){
  //     select.addEventListener('change', function() {
  //       this.classList.add('select-updated');
  //     });
  //     if (select.options[select.selectedIndex].value !== '' && select.options[select.selectedIndex].selected) {
  //       select.classList.add('select-updated');
  //     }
  //   });
  // }

  // const gforms_readonly = document.querySelectorAll('.gform_wrapper .read-only input');
  // if (gforms_readonly !== null) {
  //   Array.from(gforms_readonly).forEach(function(input){
  //     input.setAttribute('readonly', '');
  //   });
  // }
}

// function init_upload_field() {
//   const uploads = document.querySelectorAll('.gform_wrapper .ginput_container_fileupload input[type="file"]');

//   if (uploads !== null) {
//     Array.from(uploads).forEach(function(upload){
//       let label = upload.parentNode.parentNode.querySelector('.gfield_label');
//       label = label.innerHTML.replace('<span class="gfield_required">*</span>',' *');
//       upload.parentNode.setAttribute('data-text', label)

//       upload.addEventListener('change', function() {

//         this.parentNode.setAttribute("data-text", this.value.replace(/.*(\/|\\)/, '') );
//       })
//     });
//   }
// }




const gformWrapper = document.querySelector('.gform_wrapper');
if (gformWrapper !== null) {
  jQuery(document).on('gform_post_render', function(event, form_id, current_page){
    // $cform = jQuery('.gform_wrapper').clone().wrap('<p/>').parent().html();
    init_gform_values();
    initAwareBtn();
    initErrorForm();
    initPictoPj();
  });

  jQuery(document).on('gform_confirmation_loaded', function(event, formId){
    // replace confirmation with original form
    jQuery('.gform_confirmation_wrapper').replaceWith(gformWrapper);
    // gformWrapper.querySelector('.gform_ajax_spinner').style.display = 'none';
    
    // code to be trigger when confirmation page is loaded
    const sendPopin = document.querySelector('.email-send');
    const animForm = document.querySelector('.animation-form');
    let isAnim = false;
    if (sendPopin !== null && animForm !== null) {
      sendPopin.classList.add('open');
      window.location.href = window.location.href+'#sucess'
      if (animForm.dataset.animationPath !== '') {
        isAnim = true;
      }
      let animation = lottie.loadAnimation({
        container: animForm, // the dom element that will contain the animation
        renderer: 'svg',
        loop: false,
        autoplay: true,
        path: animForm.dataset.animationPath, // the path to the animation json
      });
      if (isAnim) {
        animation.play();
      }

      // setTimeout(function () {
      //   sendPopin.classList.remove('open');
      //   if (isAnim) {
      //     animation.destroy();
      //   }
      // }, 6000);
    }
  });
} else {
  initAwareBtn();
}
