function itemClickable(classItem, classLink) {
  const elements = document.querySelectorAll(classItem);
  if (elements !== null) {
    Array.from(elements).forEach((elem) => {
      const link = elem.querySelector(classLink);
      if (link !== null) {
        elem.addEventListener('click', function() {
          location.href = link.href;
        });
      }
    })
  }
}
