const initImagesLoad = () => {
  const offsetPic = document.querySelector('.scene-agence .offset-to-picture-top');
  const offsetPicWrapper = document.querySelector('.scene-agence .offset-picture-wrapper');
  const relativePic = document.querySelector('.scene-agence .offset-relative');

  const offsetPic2 = document.querySelector('.scene-projets .offset-to-picture-top');
  const offsetPicWrapper2 = document.querySelector('.scene-projets .offset-picture-wrapper');
  const relativePic2 = document.querySelector('.scene-projets .offset-relative');

  if (offsetPic !== null && offsetPic2 !== null) {
    const imgLoad1 = imagesLoaded( offsetPic );
    imgLoad1.on('done', () => {
      setRelativePic(offsetPic, offsetPicWrapper, relativePic);
    });
    const imgLoad2 = imagesLoaded( offsetPic2 );

    imgLoad2.on('done', () => {
      setRelativePic(offsetPic2, offsetPicWrapper2, relativePic2);
    });

    const checkForWindowResize = () => {
    
      if (window.innerWidth > 991 && window.innerWidth < 1600) {
        setRelativePic(offsetPic, offsetPicWrapper, relativePic);
        setRelativePic(offsetPic2, offsetPicWrapper2, relativePic2);
      }
      // else {
      //    doSomethingForLargeScreens();
      // }
    }

    window.addEventListener('resize', checkForWindowResize);
  }

}
