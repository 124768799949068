const initUl = () => {
  const uls = document.querySelectorAll('.flex-bloc ul:not(.menu), .panel-home ul:not(.menu)');

  if (uls !== null) {
    Array.from(uls).forEach(ul => {
      // if (php_to_js.iconList !== null || php_to_js.iconList !== undefined) {
        ul.style.listStyleImage = 'url('+php_to_js.iconList.url+')';
      // }
    })
  }
}
