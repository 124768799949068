
const setRelativePic = (offsetPic, offsetPicWrapper, relativePic) => {


    if (offsetPic !== null && relativePic !== null && offsetPicWrapper !== null) {
      // setTimeout(() => {
        const relativePicHeight = parseInt(window.getComputedStyle(relativePic,null).getPropertyValue('height').replace('px',''), 10);
        const offsetPicWrapperHeight = parseInt(window.getComputedStyle(offsetPicWrapper,null).getPropertyValue('height').replace('px',''), 10);

        // offsetPicWrapper
        const relativeTotalHeight = (offsetPicWrapperHeight - relativePicHeight)+ 'px';
        offsetPic.style.marginTop = '-'+relativeTotalHeight;
      // }, 50);
  }

};
