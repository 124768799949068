const loadAnimationLottie = (jsonUrl, elem) => {
  let animation = lottie.loadAnimation({
    container: elem, // the dom element that will contain the animation
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: jsonUrl, // the path to the animation json
  });
  // animation.destroy();
  if (elem.classList.contains('play-anim')) {
    animation.play();
  } else {
    animation.pause();
  }
  return animation;
}

const initAnimationLottie = () => {

  const observeAnimWrappers = document.querySelectorAll('.animation-wrapper-content');

  let observer = new IntersectionObserver((entries, observer) => { 
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        entry.target.classList.add('play-anim');
      } else {
        entry.target.classList.remove('play-anim');
      }
    });
  });

  Array.from(observeAnimWrappers).forEach((observeAnimWrapper) => {
    if (observeAnimWrapper.dataset.animationPath) {
      observer.observe(observeAnimWrapper);
      loadAnimationLottie(observeAnimWrapper.dataset.animationPath, observeAnimWrapper)
    }
  });

}
