const initArrowDown = () => {
  if (document.body.classList.contains('home')) {
    const arrowsDown = document.querySelectorAll('.panel-home .dropdown-arrow');
    if (arrowsDown !== null) {
      Array.from(arrowsDown).forEach((arrow) => {
        arrow.addEventListener('click', () => {
          scrollTo(0,arrow.parentNode.nextElementSibling.offsetTop);
        });
      });
    }
  }
};
