const initScrollUpDown = () => {
  // Initial state
  let scrollPos = 0;
  // adding scroll event
  window.addEventListener('scroll', function(){
    // detects new state and compares it with the new one
    if ((document.body.getBoundingClientRect()).top > scrollPos)
      document.body.setAttribute('data-scroll-direction', 'up');
    else
      document.body.setAttribute('data-scroll-direction', 'down');
    // saves the new position for iteration.
    scrollPos = (document.body.getBoundingClientRect()).top;
  });
}
