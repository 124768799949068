const initActiveBulletInView = () => {
  const callback = (entries, observer) => {
    entries.forEach(entry => {
      const bulletItem = document.querySelector('[data-target='+entry.target.id+']');

      if (entry.isIntersecting) {
        bulletItem.classList.add('active');
      } else {
        bulletItem.classList.remove('active');
      }
    });
  };
  const options = {
    threshold: 0.5
  };
  const observer = new IntersectionObserver(callback, options);
  const container = document.querySelectorAll('.panel-home');
  Array.from(container).forEach(element => {
    observer.observe(element);
  });
}

const initActiveBulletInFooter = () => {
  const callback = (entries, observer) => {
    entries.forEach(entry => {
      const bullets = document.querySelectorAll('.home .bullet');
      const dropdown = document.querySelector('.dropdown-arrow');
        if (entry.isIntersecting) {
          if (bullets.length > 0 && dropdown !== null) {
            bullets[bullets.length-1].classList.add('active');
            dropdown.classList.add('hide');
          }
        } else {
            dropdown.classList.remove('hide');
        }
      });
    };
    const options = {
      threshold: 0.5
    };
    const observer = new IntersectionObserver(callback, options);
    const footer = document.querySelector('#site-footer');
    observer.observe(footer);
}

const initSnapScroll = () => {
  if (document.body.classList.contains('home')) {
    const panels = document.querySelectorAll('.panel-home');
    const bullets = document.querySelectorAll('.bullets');
    if (panels !== null && bullets !== null) {
      Array.from(panels).forEach((panel) => {
        const bullet = document.createElement('span');
        bullet.classList.add('bullet');
        bullet.dataset.target = panel.id;
  
        bullet.addEventListener('click', () => {
          scrollTo(0,panel.offsetTop);
          bullet.classList.remove('active');
          bullet.classList.add('active');
        });
        bullets[0].appendChild(bullet);
      });
      initActiveBulletInView();
      initActiveBulletInFooter();
    }
  }
};
